'use client'
import { useAtom } from 'jotai'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import Icon from 'public/images/batu.svg'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import useSWR from 'swr'

import { removeItem, updateItemQuantity } from '@/app/_components/cart/actions'
import { changeCartAtom } from '@/atoms/cart'
import { ItemDetail } from '@/lib/fetcher'

import EditItemQuantityButton from './edit-item-quantity-button'

type Props = {
  cartUid: string
  lineId: number
  variationId: number
  quantity: number
  minQuantity: number | null
  maxQuantity: number | null
  refresh?: () => void
}

type CartItem = {
  id: number
  variationId: number
  quantity: number
}

const CartItem = ({
  cartUid,
  variationId,
  lineId,
  quantity: initialQuantity,
  minQuantity,
  maxQuantity,
  refresh = undefined,
}: Props) => {
  const [quantity, setQuantity] = useState(initialQuantity.toString())
  const [changeCart, setChangeCart] = useAtom(changeCartAtom)

  useEffect(() => {
    setQuantity(initialQuantity.toString())
  }, [initialQuantity])

  const router = useRouter()

  const { data, isLoading } = useSWR(
    `/api/variations/${variationId}`,
    ItemDetail,
    {
      onErrorRetry: (error) => {
        if (error.status === 404) return
      },
    },
  )

  const removeItemEvent = async () => {
    try {
      await removeItem({ cartId: cartUid, lineId: lineId }).then(() => {
        if (refresh) {
          setChangeCart(true)
          refresh()
        } else {
          router.refresh()
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return //1
    e.preventDefault() //2
    updateQuantity(quantity) //3
  }

  const updateQuantity = async (newQuantity: any) => {
    const parsedQuantity = parseInt(newQuantity, 10)
    if (Number.isNaN(parsedQuantity)) {
      toast.error('入力された数量が無効です。')
      return
    }
    if (minQuantity && parsedQuantity < minQuantity) {
      toast.error(`${minQuantity}以上の数量を入力してください。`)
      return
    }
    if (maxQuantity && parsedQuantity > maxQuantity) {
      toast.error(`${maxQuantity}以下の数量を入力してください。`)
      return
    }
    try {
      const error = await updateItemQuantity({
        cartId: cartUid,
        variationId: variationId,
        quantity: parsedQuantity,
        type: 'set',
      })
      if (error) {
        throw new Error(error.toString())
      }
      if (refresh) {
        setChangeCart(true)
        refresh()
      } else {
        router.refresh()
      }
    } catch (error: any) {
      if (error.message === '在庫がありません') {
        toast.error('在庫がありません')
      }
      console.error('数量の更新に失敗しました。', error)
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="flex flex-column flex-wrap items-center py-2 relative pb-4 border-b border-neutral-200">
          <div className="w-full flex">
            <div className="w-20 h-20 bg-neutral-100 rounded" />
            <div className="flex flex-col ml-2">
              <div className="w-20 h-4 mb-1 bg-neutral-100 rounded" />
              <div className="w-20 h-4 bg-neutral-100 rounded" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-column flex-wrap items-center py-2 relative pb-4 border-b border-neutral-200">
          <div className="w-full flex justify-between">
            <div className="flex">
              <Image
                src={
                  data?.optionName
                    ? `/images/variation/cart_${data.optionImage}`
                    : `/images/item/${data?.mainImage}`
                }
                width={80}
                height={80}
                alt={data?.name as string}
                className="rounded mr-2"
              />
              <div className="flex flex-col justify-between">
                <p className="max-w-xs font-bold">{data?.name}</p>
                {data?.optionName && (
                  <p className="max-w-xs">{data?.optionName}</p>
                )}
                <p>
                  {data?.price.toLocaleString()}
                  <span className="ml-2 text-sm font-bold text-neutral-500">
                    円
                  </span>
                </p>
              </div>
            </div>
            <Icon
              className="mt-1 ml-2 cursor-pointer hover:opacity-80"
              style={{ minWidth: '20px' }}
              onClick={() => removeItemEvent()}
            />
          </div>

          <div className="ml-auto flex h-9 flex-row items-center rounded-full border border-my-color absolute right-0 bottom-4">
            <EditItemQuantityButton
              cartUid={cartUid}
              type="minus"
              min={minQuantity}
              max={maxQuantity}
              lineId={lineId}
              variationId={variationId}
              quantity={parseInt(quantity, 10)}
              refresh={refresh ? () => refresh() : undefined}
            />
            <input
              type="number"
              className="w-12 text-center border-none no-spin"
              value={quantity}
              onKeyDown={(e) => handleInputKeyDown(e)}
              onChange={(e) => setQuantity(e.target.value)}
              min={minQuantity || 0}
              max={maxQuantity || 999}
            />
            <EditItemQuantityButton
              cartUid={cartUid}
              type="plus"
              max={maxQuantity}
              lineId={lineId}
              variationId={variationId}
              quantity={parseInt(quantity, 10)}
              refresh={refresh ? () => refresh() : undefined}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default CartItem
