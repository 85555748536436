import type { MailLog, OrderStatus, ShippingGroup } from '@/lib/types'

type SkipScheduleStatus = 'yet' | 'past' | 'cancelled'

type Subscription = {
  id: number
  status?: string
  nextPaymentDate: Date
  variation: {
    id: number
    name: string
  }
  skipSchedules: {
    id: number
    beforeDate?: Date
    skipDate: Date
    status: SkipScheduleStatus
  }[]
}

type DeliveryFlowers = {
  id: number
  paymentDate: Date
  flowerSet: {
    flower: {
      name: string
    }
    canDryFlower: boolean
  }[]
  variation: {
    name: string
  }
}

type SubscriptionDelivery = {
  id: number
  paymentData: string
  course: string
  volume: string
  deliverySchedule: string
  deliveryMethod: string
  deliveryTime: string
  isCool: boolean
  orderDetails: {
    price: number
  }[]
  deliveryFlower: {
    flowerSet: {
      flower: {
        name: string
      }
      canDryFlower: boolean
    }[]
  }
}

type Cart = {
  id: number
  userId?: string
  cartUid?: string
  customerId?: string
  totalAmount: number
  savedTotalAmount: number
  paymentIntentId?: string
  totalPostage: number
  totalDiscount: number
  totalLineItemAmount: number
  status: string
  items: CartItem[]
}

type CartItem = {
  id: number
  variationId: number
  quantity: number
  variation?: {
    id: number
    name: string
    optionName?: string
    mainImage: string
    isSubscription: boolean
    status: 'published' | 'inPreparation' | 'limitedRelease'
    subscriptionDeliveryDay?: {
      id: number
      name: string
      slug: string
      firstDay: number
      lastDay: number
    }
    shippingSizeX?: number
    shippingSizeY?: number
    shippingSizeZ?: number
    shippingVolume?: number
    postageGroup?: {
      id: number
      name: string
      type: 'free' | 'equality' | 'byRegion' | 'calculation'
    }
    shippingGroup?: ShippingGroup
    minQuantityPerShipment?: number
    maxQuantityPerShipment?: number
    shortestShippingDate?: number
  }
}

type ItemDetail = {
  id: number
  name: string
  slug?: string
  price: number
  mainImage: string
  optionName?: string
  optionImage?: string
}

type CategoryItemList = {
  category: {
    id: number
    name: string
  }
  variation: {
    id: number
    name: string
    mainImage: string
    status: 'published' | 'inPreparation' | 'limitedRelease'
  }[]
}

type UpdateCart = {
  cartId: string
  variationId: string
  action: 'increase' | 'decrease'
}

type Postage = {
  id: number
  postage: number
  groupId: number
  regionId: number
}

type Card = {
  id: string
  card: {
    brand: string
    exp_month: string
    exp_year: string
    last4: string
  }
}

type CardList = {
  cards: {
    data: Card[]
  }
  defaultCard: string
}

type Address = {
  id: number
  recipientFirstName: string
  recipientLastName: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  postalCode: string
  phoneNumber: string
  email: string
}

type AddressList = {
  address: Address[]
  userDefault: {
    defaultBillingAddressNumber: number
    defaultShippingAddressNumber: number
  }
}

type OrderDetail = {
  id: number
  variationId: number
  subscriptionDeliveryId?: number
  name: string
  quantity: number
  price: number
  optionName?: string
}

type DeliveryType = 'nextDay' | 'selectDay'

type DeliveryGroup = {
  id: number
  status: OrderStatus
  orderId?: number
  orderDetails: OrderDetail[]
  deliveryType: DeliveryType
  note?: string
  requestedDeliveryDate?: Date
  requestedDeliveryTime?: DeliveryTimeType
}

type DeliveryTimeType =
  | 'noDesignation'
  | 'am'
  | 'pm12pm14'
  | 'pm14pm16'
  | 'pm16pm18'
  | 'pm18pm20'
  | 'pm19pm21'
  | 'pm20pm21'

type Order = {
  id: number
  uId: string
  billingAddress: Address
  shippingAddress: Address
  totalAmount: number
  discountAmount: number
  totalLineItemAmount: number
  requestedDeliveryDate: Date
  orderDetails: OrderDetail[]
  deliveryGroup: DeliveryGroup[]
  sendMailLog: MailLog[]
  status: OrderStatus
  createdAt: Date
  purchaseAt: Date
  sameAsBillingAddress: boolean
  requestedDeliveryTime: DeliveryTimeType
  note: string
  isTest?: boolean
}

export const SubscriptionList = async (
  args: string,
): Promise<Subscription[]> => {
  const response = await fetch(args)
  return await response.json()
}

export const DeliveryFlowers = async (
  args: string,
): Promise<DeliveryFlowers[]> => {
  const response = await fetch(args)
  return await response.json()
}

export const OrderList = async (args: string): Promise<Order[]> => {
  const response = await fetch(args)
  return await response.json()
}

export const Order = async (args: string): Promise<Order> => {
  const response = await fetch(args)
  return await response.json()
}

export const CardList = async (args: string): Promise<CardList> => {
  const response = await fetch(args)
  return await response.json()
}

export const AddressList = async (args: string): Promise<AddressList> => {
  const response = await fetch(args)
  return await response.json()
}

export const ItemList = async (args: string): Promise<CategoryItemList[]> => {
  const response = await fetch(args)
  return await response.json()
}
export const ItemDetail = async (args: string): Promise<ItemDetail> => {
  const response = await fetch(args)
  return await response.json()
}

export const CurrentCart = async (args: string): Promise<Cart[]> => {
  const response = await fetch(args)
  return await response.json()
}

export const UpdateCart = async (args: string): Promise<UpdateCart> => {
  const response = await fetch(args)
  return await response.json()
}

export const SubscriptionDeliveryList = async (
  args: string,
): Promise<SubscriptionDelivery[]> => {
  const response = await fetch(args)
  return await response.json()
}

export const PostageList = async (args: string): Promise<Postage[]> => {
  const response = await fetch(args)
  return await response.json()
}

export const MailLogList = async (args: string): Promise<MailLog[]> => {
  const response = await fetch(args)
  return await response.json()
}
