type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const pageview = (url: string) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    })
  } else {
    console.log({
      event: 'pageview',
      page: url,
    })
  }
}

export const completePurchase = (orderUid: string) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'completePurchase',
      orderUid,
    })
  } else {
    console.log({
      event: 'purchase',
      orderUid,
    })
  }
}

export const openPaymentForm = () => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'openPaymentForm',
    })
  } else {
    console.log({
      event: 'openPaymentForm',
    })
  }
}

export const addCart = (variationId: number) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'addCart',
      variationId,
    })
  } else {
    console.log({
      event: 'addCart',
      variationId,
    })
  }
}
