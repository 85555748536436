'use client'
import { Dialog, Transition } from '@headlessui/react'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import { useAtom } from 'jotai'
import { usePathname, useRouter } from 'next/navigation'
import { Fragment, useEffect, useState } from 'react'

import { cartAtom, changeCartAtom, completeCartAtom } from '@/atoms/cart'

import CartItemComponent from './CartItem'
import CloseCart from './close-cart'
import OpenCart from './open-cart'

type CartItem = {
  id: number
  variationId: number
  quantity: number
  maxQuantity: number | null
  minQuantity: number | null
}

type Cart = {
  id: number
  cartUid: string
  lines: CartItem[]
  totalQuantity: number
  totalAmount: number
}

export default function CartModal({ cart }: { cart: Cart | undefined }) {
  const router = useRouter()
  const pathname = usePathname()
  const [isOpen, setIsOpen] = useAtom(cartAtom)
  const [isHidden, setIsHidden] = useState(false)
  const [isChangeCart, setChangeCart] = useAtom(changeCartAtom)
  const [isCompleteCart, setCompleteCart] = useAtom(completeCartAtom)
  const openCart = () => {
    if (isChangeCart || isCompleteCart) {
      setChangeCart(false)
      setCompleteCart(false)
      router.refresh()
    }
    setIsOpen(true)
  }
  const closeCart = () => setIsOpen(false)

  useEffect(() => {
    const hiddenPaths = [
      '/maintenance',
      '/checkout',
      '/checkout/order-received',
      '/order-receipts',
      '/mypage/order',
    ]
    if (hiddenPaths.includes(pathname)) {
      setIsHidden(true)
    } else {
      setIsHidden(false)
    }
  }, [pathname, setIsHidden])

  return (
    <>
      {!isHidden && (
        <>
          <button aria-label="カートを開く" onClick={openCart}>
            <OpenCart quantity={isCompleteCart ? 0 : cart?.totalQuantity} />
          </button>
          <Transition show={isOpen}>
            <Dialog onClose={closeCart} className="relative z-50">
              <Transition.Child
                as={Fragment}
                enter="transition-all ease-in-out duration-300"
                enterFrom="opacity-0 backdrop-blur-none"
                enterTo="opacity-100 backdrop-blur-[.5px]"
                leave="transition-all ease-in-out duration-200"
                leaveFrom="opacity-100 backdrop-blur-[.5px]"
                leaveTo="opacity-0 backdrop-blur-none"
              >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition-all ease-in-out duration-300"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transition-all ease-in-out duration-200"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="text-my-color fixed bottom-0 inset-x-1/2 -translate-x-2/4 flex w-full flex-col rounded-t-lg border-l border-neutral-200 bg-white p-4  backdrop-blur-xl md:w-[390px] max-h-screen">
                  <div className="flex items-center justify-between">
                    <p className="text-lg font-semibold">
                      カートに入っている商品
                    </p>

                    <button aria-label="カートを閉じる" onClick={closeCart}>
                      <CloseCart />
                    </button>
                  </div>

                  {!cart ||
                  cart.lines?.length === 0 ||
                  cart.totalQuantity === 0 ? (
                    <div className="my-12 flex w-full flex-col items-center justify-center overflow-hidden">
                      <ShoppingCartIcon className="h-8" />
                      <p className="mt-2 text-center text-sm font-bold">
                        カートに商品が入っていません。
                      </p>
                    </div>
                  ) : (
                    <div className="flex h-full flex-col justify-between overflow-hidden p-1">
                      <ul className="flex-grow overflow-auto py-4">
                        {cart.lines?.map((line, i) => (
                          <div key={i}>
                            <CartItemComponent
                              key={line.id}
                              cartUid={cart.cartUid}
                              lineId={line.id}
                              variationId={line.variationId}
                              quantity={line.quantity}
                              maxQuantity={line.maxQuantity}
                              minQuantity={line.minQuantity}
                            />
                          </div>
                        ))}
                      </ul>
                      <div className="flex w-full justify-between py-4">
                        <span className="text-lg font-bold">注文合計</span>
                        <div>
                          <span className="text-lg font-bold">
                            {cart?.totalAmount?.toLocaleString()}
                          </span>
                          <span className="ml-2 text-sm font-bold text-neutral-500">
                            円
                          </span>
                        </div>
                      </div>
                      <a
                        href="/checkout"
                        className="block w-full rounded p-3 text-center text-sm font-medium text-my-color opacity-100 hover:opacity-80 border border-my-color"
                      >
                        注文に進む
                      </a>
                      <button
                        onClick={() => closeCart()}
                        className="mt-4 text-center text-sm opacity-100 hover:opacity-80"
                      >
                        買い物を続ける
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </Dialog>
          </Transition>
        </>
      )}
    </>
  )
}
