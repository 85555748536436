import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

export default function OpenCart({
  className,
  quantity,
}: {
  className?: string
  quantity?: number
}) {
  return (
    <div className="relative flex h-11 w-11 items-center justify-center rounded-md text-my-color transition-colors">
      <ShoppingCartIcon
        className={clsx(
          'h-6 transition-all ease-in-out hover:scale-110 text-my-color ',
          className,
        )}
      />
      {quantity ? (
        <div className="absolute right-0 top-0 mr-1 mt-1 h-2 w-2 bg-medelu-pink rounded-full" />
      ) : null}
    </div>
  )
}
