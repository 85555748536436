import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { useRouter } from 'next/navigation'
import { useMemo, useTransition } from 'react'
import { toast } from 'react-hot-toast'

import { removeItem, updateItemQuantity } from '@/app/_components/cart/actions'
import LoadingDots from '@/app/_components/loading-dots'
import { changeCartAtom } from '@/atoms/cart'

export default function EditItemQuantityButton({
  cartUid,
  variationId,
  lineId,
  quantity,
  type,
  min = null,
  max = null,
  refresh = undefined,
}: {
  cartUid: string
  variationId: number
  lineId: number
  quantity: number
  type: 'plus' | 'minus'
  min?: number | null
  max?: number | null
  refresh?: () => void
}) {
  const router = useRouter()
  const [isPending, startTransition] = useTransition()
  const [, setChangeCart] = useAtom(changeCartAtom)

  const disabled = useMemo(() => {
    //typeがminusで、item.quantityがminと同じ場合
    if (type === 'minus' && quantity === min) {
      return true
    }
    //typeがplusで、item.quantityがmaxと同じ場合
    if (type === 'plus' && quantity === max) {
      return true
    }
    //isPendingがtrueの場合
    if (isPending) {
      return true
    }
    return false
  }, [type, quantity, min, max, isPending])

  return (
    <button
      aria-label={type === 'plus' ? '商品を増やす' : '商品を減らす'}
      onClick={() => {
        startTransition(async () => {
          const error =
            type === 'minus' && quantity - 1 === 0
              ? await removeItem({ cartId: cartUid, lineId: lineId })
              : await updateItemQuantity({
                  cartId: cartUid,
                  variationId: variationId,
                  type: type,
                  minQuantity: min,
                  maxQuantity: max,
                })

          if (error) {
            // Trigger the error boundary in the root error.js
            toast.error(error.toString())
          }
          if (refresh) {
            setChangeCart(true)
            refresh()
          } else {
            router.refresh()
          }
        })
      }}
      disabled={disabled}
      className={clsx(
        'ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full px-2 transition-all duration-200 border-my-color hover:opacity-80',
        {
          'cursor-not-allowed': isPending,
          'ml-auto': type === 'minus',
        },
      )}
    >
      {isPending ? (
        <LoadingDots className="bg-black" />
      ) : type === 'plus' ? (
        <PlusIcon className="h-4 w-4 dark:text-neutral-500" />
      ) : (
        <MinusIcon className="h-4 w-4 dark:text-neutral-500" />
      )}
    </button>
  )
}
