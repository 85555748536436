import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Noto_Sans_JP\",\"arguments\":[{\"weight\":[\"400\",\"500\"],\"subsets\":[\"latin\"],\"variable\":\"--font-notojp\",\"display\":\"swap\"}],\"variableName\":\"notojp\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/insta.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/medelu_logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/analytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/cart/Modal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css")